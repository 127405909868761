import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ItemListing from '../components/item-listing'
import Card from '../components/card'
import styled from 'styled-components'
import HeroArea from '../components/hero-area'
import CalloutBar from '../components/callout-bar'
import EstimateForm from '../components/estimate-form'
import ContactForm from '../components/ContactForm'
const MainContentContainer = styled.div`
  h1 {
    margin-bottom: 0.25em;
  }
`

const SidebarContainer = styled.div``
const Spacer = styled.div`
  height: 5px;
`
const MapTitle = styled.p`
  text-align: center;
  font-size: 12px;
  padding-top: 10px;
  font-weight: 600;
`

class PageTemplate extends Component {
  render() {
    const pageData = this.props.data.contentfulPage

    const showContactForm = this.props.location.pathname.includes('contact')

    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) => (
        <Card key={index} sidebar={sidebar} />
      ))

      return sideBarCards
    }

    return (
      <Layout pageProps={this.props}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
          robots={pageData.metaRobotsContent}
        />

        {pageData && pageData.heroArea ? <HeroArea heroContent={pageData && pageData.heroArea} /> : ''}
        {pageData.calloutBar ? (
          <CalloutBar calloutContent={pageData.calloutBar} />
        ) : (
          ''
        )}
        <div
          className={
            'max-w-7xl mx-auto  px-4 sm:px-6 lg:px-8 py-12' +
            (showContactForm ? ' contact-us-page' : '')
          }
        >




          <div className="flex flex-wrap">
            <div
              className={
                pageData.sidebar && !showContactForm
                  ? 'md:w-2/3 md:pr-4 prose'
                  : 'max-w-2xl mx-auto'
              }
            >

              {pageData && pageData.body && pageData.body.body ? (
                <div dangerouslySetInnerHTML={{ __html: pageData.body.body }} />
              ) : (
                ''
              )}
              <Spacer />
              {showContactForm ? (
                <ContactForm />
              ) : (
                ''
              )}

              {pageData.itemListing ? (
                <ItemListing itemList={pageData.itemListing} />
              ) : (
                ''
              )}
            </div>


            {pageData.sidebar && !showContactForm ? (
              <div className="w-full md:w-1/3 md:pl-4 space-y-4 md:space-y-6">
                <SidebarColumn />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      pageTitle
      metaTitle
      metaDescription
      slug
      metaRobotsContent
      itemListing {
        id
        serviceTitle
        excerpt
        slug
        heroImage {
          gatsbyImageData(width: 370, quality: 70)
        }
      }

      heroArea {
        id
        name
        heroTitle
        description {
          description
        }
        heroImage {
          gatsbyImageData(width: 1000, quality: 80)
        }
        primaryActionBtnLabel
        primaryActionBtnLink
      }
      body {
        body
      }
      calloutBar {
        id
        title
        description
        backgroundColor
        textColor
      }
      sidebar {
        id
        title
        content {
          id
          content
        }
        testimonialCategory {
          id
          name
        }
        cssClasses
      }
    }
  }
`






//  mb-6  font-extrabold text-2xl md:text-3xl text-base md:text-lg text-gray-600 